/**
 * Datadog RUM is initialized by GTM asynchronously and globally
 */

export function addError(error: Error) {
    if (window.DD_RUM && window.DD_RUM.addError) {
        window.DD_RUM.addError(error);
    }
}

export function addAction(action: string, context?: Record<string, any>) {
    if (window.DD_RUM && window.DD_RUM.addAction) {
        window.DD_RUM.addAction(action, context);
    }
}
