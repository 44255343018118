import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useAppContext } from '../../context/AppContext';
import { FETCH_ORDER_KEY } from '../../context/OrderContext';
import useMutationWithError from '../../hooks/useMutationWithError';
import { GiftMessage } from '../../types/api';
import { omsPublic } from '../../utils/apiOms';
import { WritingIcon } from '../Icons';

type GiftItemProps = {
    message: GiftMessage;
    onEdit: () => void;
};

const GiftItem = ({ message, onEdit }: GiftItemProps) => {
    const queryClient = useQueryClient();
    const { orderId } = useAppContext();
    const removeGiftMessage = useMutationWithError(
        () => omsPublic.deleteGiftMessage(orderId),

        {
            onSuccess: data => {
                if (data) {
                    queryClient.setQueryData(FETCH_ORDER_KEY, data);
                }
            },
        }
    );

    return (
        <div className="c-order-checkout__item">
            <div className="o-grid o-grid--gutter-sm-bis">
                <div className="o-grid__col o-grid__col--20">
                    <h3 className="u-h4-bis u-text-uppercase">
                        <FormattedMessage id="cart.gift_message.title" />
                    </h3>
                    <ul className="u-list-unstyled u-mt-n u-mt-xs-sm@md u-mt-xs-sm@md-plus">
                        <li>
                            <FormattedMessage id="cart.list.price" /> <FormattedMessage id="cart.gift_message.free" />
                        </li>
                        <li>
                            <FormattedMessage id="cart.gift_message.fields.title" /> "{message.title}"
                        </li>
                        <li>
                            <FormattedMessage id="cart.gift_message.fields.message" /> "{message.message}"
                        </li>
                    </ul>
                </div>
                <div className="o-grid__col o-grid__col--4 u-text-right">
                    <div className="u-none@sm">
                        <button
                            className="c-order-checkout__button"
                            type="button"
                            onClick={() => {
                                removeGiftMessage.mutate();
                            }}
                            disabled={removeGiftMessage.isLoading}
                        >
                            <FormattedMessage id="actions.remove_from_basket" />
                        </button>
                    </div>
                </div>
            </div>
            <p className="c-order-checkout__customization" style={{ cursor: 'pointer' }} onClick={onEdit}>
                <FormattedMessage id="actions.edit" />
                <i className="c-icon u-text-icon-xxs u-ml-xs">
                    <WritingIcon />
                </i>
            </p>
        </div>
    );
};

export default GiftItem;
